import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Player from './Player';
import Player2 from './Player2';
import BongPlayer from './BongPlayer';

const Router = () => {
  return (
    <Routes>
      <Route
        path='/:category/:playerId/live'
        element={
          <Player />
        }
      />
      <Route
        path='/:playerId/live'
        element={
          <BongPlayer />
        }
      />
      <Route
        path='/:teams/:streamId'
        element={
          <Player2 />
        }
      />
      <Route path='*' element={<Navigate to="/" />} />
    </Routes>
  );
};

export default Router;
